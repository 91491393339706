<template>
  <div class="xhs-picStore">
    <template>
      <div class="store-left" v-show="step === 1">
        <div class="left-top">
          <a-select
            ref="brandSelect"
            style="width: 100%"
            v-model="principalIds"
            option-filter-prop="children"
            show-search
            placeholder="请选择品牌"
            :maxTagCount="2"
            :open="showBrandDropDown"
            @focus="handleSelectFocus"
            @blur="showBrandDropDown = false"
            @select="showBrandDropDown = false"
            @change="handleSearchPrincipalChange"
            allowClear
          >
            <a-select-option v-for="item in principalList" :key="item.principalId" :value="item.principalId">{{
              item.principalName
            }}</a-select-option>
          </a-select>
        </div>
        <a-spin :spinning="folderLoading" size="large" tip="加载中...">
          <div class="left-bottom">
            <template v-if="!principalIds">
              <div class="none-tips">请选择品牌</div>
            </template>
            <template v-if="principalIds && folderList.length === 0">
              <div class="none-tips">暂无文件</div>
            </template>
            <template v-else>
              <div v-for="item in folderList" :key="item.principalId">
                <div
                  :class="['folder-title', { 'expand-title': item.expand }]"
                  @click="handleFolderExpand(item.principalId)"
                >
                  <a-icon type="caret-up" style="margin-right: 20px" v-show="!item.expand" />
                  <a-icon type="caret-down" style="margin-right: 20px" v-show="item.expand" />
                  <span></span>
                  <span>{{ item.principalName }}</span>
                </div>
                <div class="folder-tree" v-show="item.expand">
                  <a-tree
                    v-show="item.treeList.length > 0"
                    :selectedKeys="selectFolderKeys"
                    :tree-data="item.treeList"
                    :default-expand-all="true"
                    :replaceFields="{ title: 'name' }"
                    :show-icon="true"
                    @select="(val) => handleFolderSelect(val, item.principalId)"
                    draggable
                    @drop="(e) => handleFolderDrop(e, item.treeList)"
                  >
                    <template #title="items">
                      <a-dropdown :trigger="['contextmenu']">
                        <div>
                          <span>
                            <a-icon
                              slot="icon"
                              type="setting"
                              style="margin-right: 5px"
                              v-show="items.folderType && items.folderType === 'system'"
                            />
                            <a-icon
                              slot="icon"
                              type="user"
                              style="margin-right: 5px"
                              v-show="items.folderType && items.folderType === 'user'"
                            />{{ items.name }}</span
                          ><span style="margin-left: 15px; font-weight: bold">{{ items.fileCount || 0 }}</span>
                        </div>
                        <template #overlay>
                          <a-menu @click="(e) => onContextMenuClick(e, item, items)">
                            <a-menu-item key="1" v-show="items.id != -1">重命名</a-menu-item>
                            <a-menu-item key="2" v-show="items.id != -1">+ 子目录</a-menu-item>
                            <a-menu-item key="3">+ 同级目录</a-menu-item>
                            <a-popconfirm
                              placement="right"
                              ok-text="删除"
                              cancel-text="取消"
                              style="text-align: center; cursor: pointer"
                              @confirm.stop="handleFolderDel(items.id)"
                            >
                              <template slot="title">
                                <p>确认删除目录?</p>
                              </template>
                              <a-menu-item key="4" v-show="items.id != -1">- 删除目录</a-menu-item>
                            </a-popconfirm>
                          </a-menu>
                        </template>
                      </a-dropdown>
                    </template>
                  </a-tree>
                </div>
              </div>
            </template>
          </div>
        </a-spin>
      </div>
      <div class="store-right" v-show="step === 1">
        <div class="right-top">
          <a-row type="flex" justify="space-between" align="middle">
            <a-col :span="7">
              <a-select
                v-model="form.creator"
                style="width: 100%"
                placeholder="请选择添加人"
                show-search
                option-filter-prop="children"
                allowClear
                @change="getPicList(true)"
              >
                <a-select-option v-for="item in creatorList" :key="item.username" :value="item.username">{{
                  item.name
                }}</a-select-option>
              </a-select>
            </a-col>
            <a-col :span="7">
              <a-range-picker v-model="upTime" @change="handleDateChange" />
            </a-col>
            <a-col :span="7">
              <a-select
                style="width: 100%"
                mode="multiple"
                v-model="form.vehicleSeriesIds"
                placeholder="请选择车系"
                show-search
                :filter-option="false"
                :maxTagCount="1"
                :loading="seriesLoading"
                allowClear
                @change="getPicList(true)"
              >
                <a-spin v-if="seriesLoading" slot="notFoundContent" size="small" />
                <a-select-option v-else v-for="item in carSeriesList" :key="item.id" :value="item.id">{{
                  item.name
                }}</a-select-option>
              </a-select>
            </a-col>
          </a-row>
        </div>
        <div class="right-bottom">
          <div class="bottom-top">
            <div style="display: flex; align-items: center">
              <div style="margin-right: 25px; display: flex; align-items: center">
                颜色：
                <a-popover
                  v-model="showColor"
                  title=""
                  trigger="click"
                  placement="rightTop"
                  @visibleChange="handleColorPopChange"
                >
                  <div slot="content">
                    <Sketch v-model="currentColor" @input="updateColor" />
                    <div style="display: flex; justify-content: space-between; margin-top: 10px">
                      <a-button size="small" @click="handleColorConfirm(false)">全部</a-button>
                      <a-button size="small" type="primary" @click="handleColorConfirm">确认</a-button>
                    </div>
                  </div>
                  <span class="color-block" @click="showColor = true">
                    <span
                      class="search-color-box"
                      :style="`background-color: ${currentColor}`"
                      v-show="currentColor"
                      @click="handleColorClick(currentColor)"
                    ></span>
                    <a v-show="!currentColor" @click="handleColorClick()">全部</a>
                  </span>
                </a-popover>
              </div>
              <span
                >形状：
                <a-dropdown :trigger="['click']">
                  <a @click.prevent="() => {}"
                    ><a-icon type="down" style="margin-right: 5px" />{{ currentShapeText }}</a
                  >
                  <a-menu slot="overlay">
                    <a-menu-item v-for="item in shapeList" :key="item.value">
                      <a-button type="link" @click="handleShapeClick(item.value)">{{ item.label }}</a-button>
                    </a-menu-item>
                  </a-menu>
                </a-dropdown>
              </span>
            </div>
            <div>
              <a-space :size="10">
                <a-tooltip placement="left">
                  <template slot="title"> {{ showMode === 'list' ? '列表模式' : '看图模式' }} </template>
                  <a-icon
                    class="switch-icon"
                    type="table"
                    v-if="showMode === 'card'"
                    @click="handleSwitchType('list')"
                  />
                  <a-icon
                    class="switch-icon"
                    type="bars"
                    v-if="showMode === 'list'"
                    @click="handleSwitchType('card')"
                  />
                </a-tooltip>
                <a-button class="action-btn" :loading="operateLoading" @click="batchToMyPicStore"
                  >存入我的图库</a-button
                >
                <a-button class="action-btn" :loading="operateLoading" @click="batchDeleteImageByIds"
                  >批量删除</a-button
                >
                <a-button class="action-btn" type="primary" @click="handleUpload">上传图片</a-button>
              </a-space>
            </div>
          </div>
          <div class="bottom-bottom">
            <a-spin :spinning="spinning" size="large" tip="加载中...">
              <template v-if="picList.length">
                <template v-if="showMode === 'list'">
                  <a-table
                    :columns="columns"
                    :data-source="picList"
                    :pagination="false"
                    :row-selection="rowSelection"
                    :rowKey="(record) => record.fileId"
                    :scroll="{ y: 'calc(100vh - 400px)' }"
                  >
                    <div slot="info" slot-scope="text, record">
                      <a-popover placement="right">
                        <template slot="content">
                          <img :src="record.file.fileUrl || ''" width="320px" referrerpolicy="no-referrer" />
                        </template>
                        <div
                          @click="handlePicPreview([record.file.fileUrl])"
                          class="preview-img"
                          :style="`background-image: url('${record.file.previewUrl || record.file.fileUrl || ''}')`"
                        ></div>
                      </a-popover>
                    </div>
                    <div slot="operation" slot-scope="text, record">
                      <a-space :size="1">
                        <a-button type="link" @click="handleEditPic(record.file)">编辑图片</a-button>
                        <a-button type="link" @click="singleToMyPicStore(record.file)">存入图库</a-button>
                        <a-popconfirm
                          title="删除该图片?"
                          placement="right"
                          ok-text="删除"
                          cancel-text="取消"
                          @confirm="handleDelSinglePic(record.file.id)"
                        >
                          <a-button type="link">删除</a-button>
                        </a-popconfirm>
                      </a-space>
                    </div>
                  </a-table>
                </template>
                <template v-if="showMode === 'card'">
                  <div class="card-line">
                    <PictureCardComp
                      v-for="item in picList"
                      :key="item.id"
                      :picData="item.file"
                      @preview="handlePicPreview"
                      @handleDelSinglePic="handleDelSinglePic"
                      @singleToMyPicStore="singleToMyPicStore"
                      @handleEditPic="handleEditPic"
                      @handleCheckImg="(list) => (imgCheckIdList = list)"
                      @refresh="getPicList"
                      :imgCheckIdList="imgCheckIdList"
                    />
                  </div>
                </template>
                <base-pagination
                  :currentPage="pagination.current"
                  :pageSize="pagination.pageSize"
                  :options="['20', '50', '100']"
                  :total="pagination.total"
                  @onChange="handlePaginationChange"
                  @onShowSizeChange="handlePaginationChange"
              /></template>
              <template v-else>
                <div class="none-tips">暂无图片</div>
              </template>
            </a-spin>
          </div>
        </div>
      </div>
    </template>

    <UploadPicComp ref="UploadPicComp" v-show="step === 2" :principalList="principalList" @back="handleBack" />

    <FolderEditModalComp ref="FolderEditModalComp" @success="getFolderList" />
  </div>
</template>

<script>
import { api as viewerApi } from 'v-viewer';
import PictureCardComp from './components/PictureCardComp';
import FolderEditModalComp from './components/FolderEditModalComp.vue';
import UploadPicComp from './components/UploadPicComp.vue';
import { Sketch } from 'vue-color';
import api from './api';

export default {
  name: 'xhsPicStore',
  props: {},
  components: { PictureCardComp, FolderEditModalComp, Sketch, UploadPicComp },
  data() {
    const columns = [
      {
        title: '基本信息',
        scopedSlots: { customRender: 'info' },
      },
      {
        title: '品牌',
        align: 'center',
        dataIndex: 'principalName',
        customRender: (text) => {
          return text || '-';
        },
      },
      {
        title: '车系',
        align: 'center',
        dataIndex: 'attrName',
        customRender: (text) => {
          return text || '-';
        },
      },
      {
        title: '上传时间',
        customRender: (text) => {
          return text.file?.ctime || '-';
        },
      },
      {
        align: 'center',
        width: 300,
        title: '操作',
        scopedSlots: { customRender: 'operation' },
      },
    ];
    const shapeList = [
      {
        value: undefined,
        label: '全部',
      },
      {
        value: 'LANDSCAPE',
        label: '横图',
      },
      {
        value: 'PORTRAIT',
        label: '竖图',
      },
      {
        value: 'SQUARE',
        label: '方图',
      },
    ];
    return {
      columns,
      shapeList,
      form: {
        creator: undefined,
        vehicleSeriesIds: undefined,
      },
      principalList: [],
      principalIds: undefined,
      creatorList: [],
      upTime: [],
      seriesLoading: false,
      operateLoading: false,
      folderLoading: false,
      spinning: false,
      showBrandDropDown: false,
      carSeriesList: [],
      showMode: 'list',
      imgCheckIdList: [],
      picList: [],
      pagination: {
        current: 1,
        pageSize: 20,
        total: 0,
      },
      selectedRowKeys: [],
      folderList: [],
      selectFolderKeys: undefined,
      showColor: false,
      currentShapeText: '全部',
      currentColor: '',
      templateColor: '',
      colorSearchParams: {},
      currentOpenFolderId: undefined,
      step: 1,
    };
  },
  computed: {
    rowSelection() {
      const { selectedRowKeys } = this;
      return {
        selectedRowKeys,
        onChange: this.onSelectChange,
      };
    },
  },
  watch: {},
  created() {
    this.init();
  },
  mounted() {
    const showMode = localStorage.getItem('imgShowMode');
    if (!showMode) {
      localStorage.setItem('imgShowMode', 'list');
    } else {
      this.showMode = showMode;
    }
  },
  methods: {
    async init() {
      this.getCreatorList();
      await this.getBrandList();
      const localPrincipal = localStorage.getItem('searchPicStorePrincipal');
      if (localPrincipal) {
        this.principalIds = localPrincipal;
        this.getSeriesList(localPrincipal);
        this.getFolderList();
      }
      this.getPicList(true);
    },
    async getPicList(init = false) {
      this.selectedRowKeys = [];
      this.imgCheckIdList = [];
      if (init) this.pagination.current = 1;
      const params = {
        ...this.form,
        principalIds: this.principalIds ? [this.principalIds] : undefined,
        color: this.currentColor ? this.colorSearchParams : undefined,
        folderId: this.selectFolderKeys?.length ? this.selectFolderKeys[0] : undefined,
        page: this.pagination.current,
        size: this.pagination.pageSize,
      };
      this.spinning = true;
      this.handleRequest(
        'getXhsPicList',
        (data) => {
          this.picList = data.list.map((item) => {
            if (item.file.fileUrl.indexOf('xhscdn') !== -1) {
              const img = new Image();
              img.setAttribute('referrerpolicy', 'no-referrer');
              img.src = item.file.fileUrl;
            }
            let previewUrl = '';
            if (item.file.fileUrl) {
              if (
                item.file.fileUrl.indexOf('xhscdn') === -1 &&
                item.file.fileUrl.indexOf('snapshot') == -1 &&
                (item.file.fileUrl.indexOf('content') > -1 || item.file.fileUrl.indexOf('afanti') > -1)
              ) {
                if (item.file.fileUrl.indexOf('x-oss-process') > -1) {
                  previewUrl = item.file.fileUrl + `/resize,l_220,m_lfit`;
                } else {
                  previewUrl = item.file.fileUrl + `?x-oss-process=image/resize,l_220,m_lfit`;
                }
              }
            }
            item.file.previewUrl = previewUrl;
            return { ...item };
          });
          this.pagination.total = data.total;
        },
        params
      ).finally(() => (this.spinning = false));
    },
    async getCreatorList() {
      this.handleRequest('getPictureCreatorList', (data) => {
        this.creatorList = data;
      });
    },
    getBrandList() {
      this.handleRequest(
        'getBrandManageList',
        (data) => {
          this.principalList = data.list;
        },
        { size: 999, page: 1 }
      );
    },
    getSeriesList(principalIds) {
      this.seriesLoading = true;
      this.handleRequest(
        'getNewTypeList',
        (data) => {
          this.carSeriesList = data.map((val) => ({ id: val.id, name: val.name }));
        },
        { principalIds }
      ).finally(() => (this.seriesLoading = false));
    },
    handleSearchPrincipalChange(value) {
      this.principalIds = value;
      this.form.vehicleSeriesIds = undefined;
      this.$refs.brandSelect.blur();
      this.picList = [];
      this.selectFolderKeys = [];
      this.carSeriesList = [];
      if (value) {
        this.getFolderList();
        this.getSeriesList(value);
        localStorage.setItem('searchPicStorePrincipal', value);
      } else {
        this.folderList = [];
        localStorage.removeItem('searchPicStorePrincipal');
      }
      this.getPicList(true);
    },
    handleSelectFocus() {
      if (this.showBrandDropDown) return;
      this.showBrandDropDown = true;
    },
    handleDateChange() {
      if (this.upTime[0] && this.upTime.length > 0) {
        this.form.startDateTime = this.$moment(this.upTime[0]._d).format('YYYY-MM-DD') + ' 00:00:00';
        this.form.endDateTime = this.$moment(this.upTime[1]._d).format('YYYY-MM-DD') + ' 23:59:59';
      } else {
        this.form.startDateTime = undefined;
        this.form.endDateTime = undefined;
      }
      this.getPicList(true);
    },
    handleSwitchType(type) {
      this.selectedRowKeys = [];
      this.imgCheckIdList = [];
      this.showMode = type;
      localStorage.setItem('imgShowMode', type);
    },
    handlePaginationChange(current, pageSize) {
      this.selectedRowKeys = [];
      this.imgCheckIdList = [];
      this.pagination.current = current;
      this.pagination.pageSize = pageSize;
      this.getPicList();
    },
    batchToMyPicStore() {
      const selectedRowKeys = this.showMode === 'list' ? this.selectedRowKeys : this.imgCheckIdList;
      if (!selectedRowKeys.length) return this.$message.info('请至少选择一张图片');
      const params = [];
      this.picList.forEach((img) => {
        selectedRowKeys.forEach((select) => {
          if (img.fileId === select) {
            params.push({
              fileId: img.fileId,
              url: img.file.fileUrl,
            });
          }
        });
      });
      this.operateLoading = true;
      this.handleRequest(
        'handleBatchToMyPicStore',
        () => {
          this.selectedRowKeys = [];
          this.imgCheckIdList = [];
          this.$message.success('操作成功');
        },
        params
      ).finally(() => (this.operateLoading = false));
    },
    batchDeleteImageByIds() {
      const selectedRowKeys = this.showMode === 'list' ? this.selectedRowKeys : this.imgCheckIdList;
      if (!selectedRowKeys.length) return this.$message.info('请至少选择一张图片');
      let that = this;
      this.$confirm({
        title: `共计${selectedRowKeys.length}张图片，请确认是否删除?`,
        okText: '确定',
        cancelText: '取消',
        onOk() {
          that.operateLoading = true;
          that
            .handleRequest(
              'handleDeletePicture',
              () => {
                if (that.pagination.current > 1 && that.picList.length == selectedRowKeys.length) {
                  that.pagination.current--;
                }
                that.$message.success('删除成功');
                that.getPicList();
              },
              { ids: selectedRowKeys }
            )
            .finally(() => (that.operateLoading = false));
        },
      });
    },
    async singleToMyPicStore(data) {
      this.spinning = true;
      const params = [
        {
          fileId: data.id,
          url: data.fileUrl,
        },
      ];
      this.handleRequest(
        'handleBatchToMyPicStore',
        () => {
          this.$message.success('操作成功');
        },
        params
      ).finally(() => {
        this.spinning = false;
      });
    },
    handleEditPic(file) {
      let imageUrlList = [file.fileUrl];
      this.handleRequest(
        'saveImageList',
        (data) => {
          window.open(`${process.env.VUE_APP_TEMPLATE_EDITOR}?localId=${data}&fileId=${file.id}`, '_blank');
        },
        { configType: 'imageEdit', data: { imageUrlList } }
      );
    },
    async handleDelSinglePic(fileId) {
      this.spinning = true;
      this.handleRequest(
        'handleDeletePicture',
        () => {
          if (this.pagination.current > 1 && this.picList.length == 1) {
            this.pagination.current--;
          }
          this.$message.success('删除成功');
          this.getPicList();
        },
        { ids: [fileId] }
      ).finally(() => {
        this.spinning = false;
      });
    },
    handlePicPreview(images) {
      const $viewer = this.$viewerApi({
        options: {
          toolbar: true,
          index: 0,
        },
        images,
      });
    },
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
    },
    handleShapeClick(value) {
      this.form.imageShape = value;
      this.currentShapeText = this.shapeList.find((item) => item.value === this.form.imageShape).label || '全部';
      this.getPicList(true);
    },
    handleUpload() {
      if (this.folderList?.length) {
        const principalId = this.folderList.find((item) => item.expand)?.principalId;
        const folderId = this.selectFolderKeys && this.selectFolderKeys[0] ? this.selectFolderKeys[0] : undefined;
        principalId && this.$refs.UploadPicComp.init({ principalId, folderId });
      }
      this.step = 2;
    },
    handleBack() {
      this.step = 1;
      this.getPicList(true);
      this.getFolderList();
    },

    /**
     * 文件夹相关
     *
     * */

    getFolderList() {
      this.folderLoading = true;
      this.handleRequest(
        'getPictureFolderTree',
        (data) => {
          this.folderList = data.map((item, index) => {
            // if (this.currentOpenFolderId && item.principalId == this.currentOpenFolderId) {
            //   item.expand = true;
            // } else {
            //   item.expand = false;
            // }
            index == 0 && (item.expand = true);
            item.treeList = item.treeList.map(this.initTreeData);
            return item;
          });
        },
        {
          isDir: 1,
          principalIds: this.principalIds,
        }
      ).finally(() => {
        this.folderLoading = false;
      });
    },

    handleFolderSelect(val, principalId) {
      this.selectFolderKeys = val;
      this.getPicList(true);
    },

    onContextMenuClick({ key: eventState }, data, item) {
      if (eventState === '1') {
        // 重命名
        if (item.id == -1) return this.$message.error('其他文件夹不支持重命名');
        this.$refs['FolderEditModalComp'].openModal({
          title: '重命名',
          form: {
            fileName: item.name,
            id: item.id,
          },
        });
      }
      if (eventState === '2') {
        // 添加下级目录
        this.$refs['FolderEditModalComp'].openModal({
          title: '添加子目录',
          form: {
            fileName: '',
            parentId: item.id,
            principalId: data.principalId,
            principalName: data.principalName,
          },
        });
      }
      if (eventState === '3') {
        // 添加同级目录
        this.$refs['FolderEditModalComp'].openModal({
          title: '添加同级目录',
          form: {
            fileName: '',
            parentId: item.parentId,
            principalId: data.principalId,
            principalName: data.principalName,
          },
        });
      }
    },
    async handleFolderDel(id) {
      if (id == -1) return this.$message.error('其他文件夹不支持删除');
      this.folderLoading = true;
      this.handleRequest(
        'deletePictureFolder',
        () => {
          this.$message.success('删除成功');
          this.getFolderList();
        },
        id
      ).finally(() => {
        this.folderLoading = false;
      });
    },
    handleFolderExpand(principalId) {
      this.currentOpenFolderId = undefined;
      this.selectFolderKeys = [];
      this.folderList.forEach((item) => {
        if (item.principalId === principalId) {
          item.expand = !item.expand;
          if (item.expand) {
            this.currentOpenFolderId = principalId;
            if (this.selectFolderKeys?.length && this.selectFolderKeys.includes('-1')) this.getPicList(true);
          } else {
            this.currentOpenFolderId = undefined;
          }
        } else {
          item.expand = false;
        }
      });
    },
    handleFolderDrop(info, tData) {
      let dropParams = {
        id: info.dragNode.value,
        parentId: undefined,
        folderIds: [],
      };

      const loop = (data, key, callback) => {
        data.forEach((item, index, arr) => {
          if (item.key === key) {
            return callback(item, index, arr);
          }
          if (item.children) {
            return loop(item.children, key, callback);
          }
        });
      };

      const dropKey = info.node.eventKey;
      const dragKey = info.dragNode.eventKey;
      const dropPos = info.node.pos.split('-');
      const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1]);

      const data = [...tData];
      let dragObj = {};
      loop(data, dragKey, (item, index, arr) => {
        arr.splice(index, 1);
        dragObj = item;
      });

      if (!info.dropToGap) {
        loop(data, dropKey, (item) => {
          item.children = item.children || [];
          item.children.push(dragObj);
          dropParams.parentId = item.id;
          dropParams.folderIds = item.children.map((item) => item.id);
        });
      } else if ((info.node.children || []).length > 0 && info.node.expanded && dropPosition === 1) {
        loop(data, dropKey, (item) => {
          item.children = item.children || [];
          item.children.unshift(dragObj);
          dropParams.parentId = item.id;
          dropParams.folderIds = item.children.map((item) => item.id);
        });
      } else {
        let ar = [];
        let i = 0;
        loop(data, dropKey, (item, index, arr) => {
          dropParams.parentId = item.parentId;
          ar = arr;
          i = index;
        });
        if (dropPosition === -1) {
          ar.splice(i, 0, dragObj);
        } else {
          ar.splice(i + 1, 0, dragObj);
        }
        dropParams.folderIds = ar.map((item) => item.id);
      }
      if (dropParams.id == -1) {
        this.$message.error('其他文件夹不支持拖动');
        this.getFolderList();
      } else {
        this.handleDropConfirm(dropParams);
      }
    },

    handleDropConfirm(dropParams) {
      this.handleRequest(
        'movePictureFolder',
        () => {
          this.$message.success('操作成功');
        },
        dropParams
      ).finally(() => {
        this.getFolderList();
      });
    },

    initTreeData(data) {
      if (data.children && data.children.length > 0) {
        data.children = data.children.map(this.initTreeData);
      }
      return {
        ...data,
        value: data.id,
        key: data.id,
      };
    },

    /**
     * 颜色相关
     *
     * */
    updateColor(value) {
      this.currentColor = value.hex8;
      const { r, g, b, a } = value.rgba;
      this.colorSearchParams = { r, g, b, a };
    },
    handleColorPopChange(value) {
      if (!value) {
        if (!this.templateColor) {
          this.currentColor = '';
          this.colorSearchParams = {};
        } else {
          this.currentColor = this.templateColor;
        }
      }
    },
    handleColorClick(currentColor) {
      if (currentColor) {
        this.templateColor = currentColor;
      } else {
        this.templateColor = '';
        this.currentColor = '';
        this.colorSearchParams = {};
      }
    },
    handleColorConfirm(searchColor = true) {
      this.showColor = false;
      if (!searchColor) {
        this.templateColor = '';
        this.currentColor = '';
        this.colorSearchParams = {};
      }
      this.getPicList(true);
    },

    async handleRequest(APINAME, callbackFn, PARAMS = null) {
      return new Promise(async (resolve) => {
        const { code, data, message } = await api[APINAME](PARAMS);
        if (code === 200) {
          callbackFn(data);
          resolve();
        } else {
          this.$message.error(`${message}`);
          resolve();
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.xhs-picStore {
  display: flex;
  padding: 10px;
  min-height: calc(100vh - 120px);

  .store-left {
    flex-shrink: 0;
    width: 300px;
    background-color: #fff;
  }

  .store-right {
    flex: 1;
    background-color: #f0f2f5;
  }
}

.store-left {
  margin-right: 10px;
  border: 1px solid #ccc;
  .left-top {
    padding: 10px;
    border-bottom: 1px solid #ccc;
  }
  .left-bottom {
    width: 100%;
    min-height: 200px;
    max-height: 675px;
    overflow-y: auto;
  }
}

.store-right {
  display: flex;
  flex-direction: column;
  .right-top {
    padding: 10px;
    margin-bottom: 10px;
    background-color: #fff;
    border: 1px solid #ccc;
  }

  .right-bottom {
    flex: 1;
    background-color: #fff;
    border: 1px solid #ccc;

    .bottom-top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px;
      border-bottom: 1px solid #ccc;
    }

    .bottom-bottom {
      padding: 10px;
      overflow-y: auto;
      .preview-img {
        margin-right: 5px;
        width: 80px;
        height: 100px;
        background-size: cover;
        background-position: center;
        cursor: pointer;
      }
    }
  }

  .card-line {
    display: grid;
    padding: 15px;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 20px 10px;
  }
}

.none-tips {
  width: 100%;
  height: 300px;
  line-height: 300px;
  text-align: center;
  font-size: 20px;
  color: #999;
  user-select: none;
}

.switch-icon {
  font-weight: bolder;
  font-size: 25px;
  height: 32px;
  line-height: 36px;
  color: #7f7f7f;
  cursor: pointer;
}

.folder-title {
  display: flex;
  align-items: center;
  padding: 15px;
  height: 60px;
  font-weight: bolder;
  cursor: pointer;
  user-select: none;
  border-bottom: 1px solid #ccc;
}

.expand-title {
  background-color: #bae7ff;
}

.color-block {
  height: 30px;
  line-height: 30px;
}

.search-color-box {
  display: inline-block;
  width: 30px;
  height: 30px;
  border-radius: 8px;
  border: 1px solid #ccc;
  cursor: pointer;
}

.folder-tree {
  border-bottom: 1px solid #ccc;
}
</style>
